/*
* Webslide - v3.1
* Web Slide - Responsive Mega Menu for Bootstrap 3+
*
* Copyright 2016 webthemex
* http://codecanyon.net/user/webthemex?ref=webthemex
*
* Licensed under Envato licenses
* http://codecanyon.net/licenses/standard
*/

/*------------------------------------ Desktop CSS ---------------------------------------------*/
.wsmobileheader {
  display: none;
}

.overlapblackbg {
  display: none;
}

.wsdownmenu {
  font-family: Helvetica, sans-serif;
  color: #fff;
  position: relative;
  font-size: 15px;
  padding: 0px;
  margin: 0px auto;
  width: 100%;
  max-width: 1200px;
  background-color: #ffffff;
  -webkit-border-radius: 4px 4px 0px 0px;
  -moz-border-radius: 4px 4px 0px 0px;
  border-radius: 4px 4px 0px 0px;
}

.wsdownmenu-list {
  text-align: left;
  margin: 0 auto 0 auto;
  width: 100%;
  display: table;
  padding: 0px;
}

.wsdownmenu-list > li {
  text-align: center;
  display: table-cell;
}

.wsdownmenu-list li ul li a .fa.fa-angle-double-right {
  font-size: 12px;
  margin: 0 3px 0 -4px;
}

.wsdownmenu-list li a .arrow:after {
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-top: 4px solid #b3b3b3;
  content: "";
  float: right;
  height: 0;
  margin: 0 0 0 9px;
  position: absolute;
  text-align: right;
  top: 22px;
  width: 0;
}

.wsdownmenu-list > li > a {
  display: block;
  background-color: #fff;
  color: $purpleblue-dark;
  padding: 0px 9px;
  font-family: $font-title;
  line-height: 52px;
  //border-right:1px solid rgba(0,0,0,0.10);
  text-decoration: none;
  position: relative;
  .fa {
    display: inline-block;
    font-size: 14px;
    line-height: inherit;
    margin-right: 0px;
  }
}

.wsdownmenu-list li a:hover .arrow:after {
  border-top-color: #b3b3b3
}

.wsdownmenu-list li a.active .arrow:after {
  border-top-color: #b3b3b3
}

.wsdownmenu-list li:hover > a .arrow:after {
  border-top-color: #b3b3b3
}

.megamenu iframe {
  width: 100%;
  margin-top: 10px;
  min-height: 200px;
}

.megamenu video {
  width: 100%;
  margin-top: 10px;
  min-height: 200px;
}

/*For megamenu desktop */
.wsdownmenu-list li:hover .megamenu {
  opacity: 1;
}

.megamenu {
  width: 100%;
  left: 0px;
  position: absolute;
  top: 48px;
  color: #000;
  z-index: 1000;
  margin: 0px;
  text-align: left;
  padding: 14px;
  font-size: 15px;
  border: solid 1px #eeeeee;
  background-color: #fff;
  opacity: 0;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
}

.megamenu .title {
  border-bottom: 1px solid #CCC;
  font-size: 14px;
  padding: 9px 5px 9px 0px;
  font-size: 17px;
  color: #424242;
  margin: 0px 0px 7px 0px;
  text-align: left;
  height: 39px;
}

.link-list li {
  display: block;
  text-align: center;
  white-space: nowrap;
  text-align: left;
}

.link-list li a {
  line-height: 18px;
  border-right: none;
  text-align: left;
  padding: 6px 0px;
  background: #fff !important;
  background-image: none !important;
  color: #666666 !important;
  border-right: 0 none !important;
  display: block;
  border-right: 1px solid #e7e7e7;
  background-color: #fff;
  color: #424242;
}

.link-list .fa {
  font-size: 11px;
}

.megacollink {
  width: 23%;
  float: left;
  margin: 0% 1%;
}

.megacollink li {
  display: block;
  text-align: center;
  white-space: nowrap;
  text-align: left;
}

.megacollink li a {
  line-height: 18px;
  border-right: none;
  text-align: left;
  padding: 8px 0px;
  background: #fff !important;
  background-image: none !important;
  color: #666666 !important;
  border-right: 0 none !important;
  display: block;
  border-right: 1px solid #e7e7e7;
  background-color: #fff;
  color: #424242;
}

.megacollink .fa {
  font-size: 11px;
}

.megacolimage {
  width: 31.33%;
  float: left;
  margin: 0% 1%;
}

.typographydiv {
  width: 100%;
  margin: 0% 0%;
}

.typographylinks {
  width: 25%;
  float: left;
  margin: 0% 0%;
}

.mainmapdiv {
  width: 100%;
  display: block;
  margin: 0% 0%;
}

.wsdownmenu-list .ad-style {
  width: 28%;
  float: right;
}

.wsdownmenu-list .ad-style a {
  border: none !important;
  padding: 0px !important;
  margin: 0px !important;
  line-height: normal !important;
  background-image: none !important;
}

.wsdownmenu-list .megamenu li:hover > a {
  background: transparent !important;
}

.wsdownmenu-list .megamenu li a:hover {
  background: transparent !important;
  text-decoration: underline;
}

.wsdownmenu-list .megamenu li .fa {
  margin-right: 5px;
  text-align: center;
  width: 18px;
}

.mrgtop {
  margin-top: 15px;
}

.show-grid div {
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #dbdbdb;
  border: 1px solid #e7e7e7;
  color: #6a6a6a;
  margin: 2px 0px;
}

/*For halfmenu */
.halfmenu {
  width: 40%;
  right: auto !important;
  left: auto !important;
}

.halfmenu .megacollink {
  width: 48%;
  float: left;
  margin: 0% 1%;
}

/*For halfmenu */

/*Form for desktop */
.halfdiv {
  width: 35%;
  right: 0px !important;
  left: auto;
}

.menu_form {
  width: 100%;
  display: block;
}

.menu_form input[type="text"] {
  width: 100%;
  border: 1px solid #e2e2e2;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #000;
  font-size: 13px;
  padding: 8px 5px;
  margin-bottom: 8px;
}

.menu_form textarea {
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  color: #000;
  font-size: 13px;
  padding: 8px 5px;
  margin-bottom: 8px;
  min-height: 122px;
}

.menu_form input[type="submit"] {
  width: 25%;
  display: block;
  height: 28px;
  float: right;
  border: solid 1px #ccc;
  margin-right: 15px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.menu_form input[type="button"] {
  width: 25%;
  display: block;
  height: 28px;
  float: right;
  border: solid 1px #ccc;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.megamenu .left.carousel-control {
  padding-top: 20%;
}

.megamenu .right.carousel-control {
  padding-top: 20%;
}

.carousel-inner .item img {
  width: 100%;
}

.megamenu .carousel-caption {
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  height: 31px;
  left: 0;
  padding: 7px 0;
  right: 0;
  width: 100%;
}

/*Animation*/
.wsdownmenu-list li > .wsdownmenu-submenu {
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  visibility: hidden;
}

.wsdownmenu-list li:hover > .wsdownmenu-submenu {
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}

.wsdownmenu-submenu li > .wsdownmenu-submenu-sub {
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  visibility: hidden;
}

.wsdownmenu-submenu li:hover > .wsdownmenu-submenu-sub {
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}

.wsdownmenu-submenu-sub li > .wsdownmenu-submenu-sub-sub {
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  visibility: hidden;
}

.wsdownmenu-submenu-sub li:hover > .wsdownmenu-submenu-sub-sub {
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}

.wsdownmenu-list li > .megamenu {
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  visibility: hidden;
}

.wsdownmenu-list li:hover > .megamenu {
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}

/* Submenu CSS */
.wsdownmenu-submenu {
  position: absolute;
  top: 52px;
  z-index: 1000;
  margin: 0px;
  padding: 2px;
  min-width: 240px;
  max-width: 100%;
  background-color: $purpleblue;
  opacity: 0;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  li {
    position: relative;
    padding: 0px;
    margin: 0px;
    display: block;
    a {
      background: transparent;
      color: #FFF;
      text-align: left;
      display: block;
      line-height: 19px;
      padding: 6px 12px;
      text-transform: none;
      font-size: 13px;
      letter-spacing: normal;
    }
  }
  > li {
    padding: 5px 10px;

    @include mq(xs) {
      padding: 0;
    }

    > a {
      padding: 5px 10px;
      font-size: 14px;
    }
    > a:hover {
      border-radius: 0px !important;
      text-decoration: none;
      text-decoration: none;
    }

    .submenu {
      display: none;
      position: absolute;
      left: 100%;
      top: 0;
      width: inherit;
      width: 240px;
      background-color: #9aa4c0;
    }

    &:hover {
      .submenu {
        display: block;
      }
    }

    @include mq(xs) {
      .fa {
        display: none;
      }
      .submenu {
        display: block;
        position: static;
        background-color: transparent;
        width: auto;
      }
    }
  }
  .fa {
    margin-right: 7px;
  }

}

.wsdownmenu-list li:hover .wsdownmenu-submenu {
  display: block;
}

.wsdownmenu-list .wsdownmenu-submenu .wsdownmenu-submenu-sub {
  min-width: 220px;
  position: absolute;
  left: 100%;
  top: 0;
  margin: 0px;
  padding: 0px;
  opacity: 0;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.4s, opacity 0.4s;
  -ms-transition: -ms-transform 0.4s, opacity 0.4s;
  -moz-transition: -moz-transform 0.4s, opacity 0.4s;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
}

.wsdownmenu-list .wsdownmenu-submenu li:hover .wsdownmenu-submenu-sub {
  opacity: 1;
  list-style: none;
  padding: 2px;
  border: solid 1px #eeeeee;
  background-color: #fff;
}

.wsdownmenu-list .wsdownmenu-submenu li:hover .wsdownmenu-submenu-sub {
  display: block;
}

.wsdownmenu-list .wsdownmenu-submenu .wsdownmenu-submenu-sub .wsdownmenu-submenu-sub-sub {
  min-width: 220px;
  position: absolute;
  left: 100%;
  top: 0;
  margin: 0px;
  padding: 0px;
  opacity: 0;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.4s, opacity 0.4s;
  -ms-transition: -ms-transform 0.4s, opacity 0.4s;
  -moz-transition: -moz-transform 0.4s, opacity 0.4s;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
}

.wsdownmenu-list .wsdownmenu-submenu .wsdownmenu-submenu-sub li:hover .wsdownmenu-submenu-sub-sub {
  opacity: 1;
  list-style: none;
  padding: 2px;
  border: solid 1px #eeeeee;
  background-color: #fff;
}

.wsdownmenu-click {
  display: none;
}

.wsdownmenu-click02 {
  display: none;
}

/* Default Theme */
.wsdownmenu-list {
  > li {
    a {
      font-family: $font-title;
    }
    > a:hover {
      background-color: $purpleblue;
      text-decoration: none;
    }
    > a.active {
      background-color: rgba(0, 0, 0, 0.08) !important;
      text-decoration: none;
    }
  }
  li:hover {
    > a {
      background-color: $purpleblue;
      color: #FFF;
      text-decoration: none;
    }
  }

}

.typography-text {
  padding: 0px 0px;
  font-size: 15px;
}

.typography-text p {
  text-align: justify;
  line-height: 24px;
  color: #656565;
}

.typography-text ul li {
  display: block;
  padding: 2px 0px;
  line-height: 22px;
}

.typography-text ul li a {
  color: #656565;
}

.hometext {
  display: none;
}

.wsdown-menuopner {
  display: none !important;
}

@media only screen and (min-width: 780px) and (max-width: 1023px) {
  .wsdownmenu-list > li > a > .fa {
    display: none !important;
  }
  .hometext {
    display: block !important;
  }
  .wsdownmenu {
    font-size: 13px !important;
  }
  .wsdownmenu-list li a {
    white-space: nowrap !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .megacollink {
    width: 48%;
    margin: 1% 1%;
  }
  .typographylinks {
    width: 48%;
    margin: 1% 1%;
  }
}

@media only screen and (min-width: 781px) and (max-width: 1200px) {
  .wsdownmenu-list li a .arrow:after {
    display: none !important;
  }

}

@media only screen and (min-width: 781px) {
  .wsdownmenu-list li:hover > .wsdownmenu-submenu {
    display: block !important;
  }
  .wsdownmenu-submenu li:hover > .wsdownmenu-submenu-sub {
    display: block !important;
  }
  .wsdownmenu-submenu-sub li:hover > .wsdownmenu-submenu-sub-sub {
    display: block !important;
  }
  .wsdownmenu-list li:hover > .megamenu {
    display: block !important;
  }

}

.wsdownmenu-animated-arrow {
  display: none;
}

.wsdownmenu-text {
  display: none;
}

/*------------------------------------ Mobile CSS ---------------------------------------------*/

@media only screen and (max-width: 780px) {

  .hometext {
    display: inline-block;
  }
  .wsdownmenu-mobile {
    display: block;
  }
  .wsdown-mobile {
    margin: 0px;
    padding: 0px;
    list-style: none;
    float: none;
    width: 100%;
  }
  .wsdown-mobile > li {
    margin: 0px 0px 0px 0px !important;
    display: block;
    position: relative;
  }
  .wsdown-mobile > li > a {
    display: block;
    color: #fff;
    padding: 15px 25px;
    background-color: $purpleblue;
  }
  .wsdown-mobile > li > a.active {
    box-shadow: none;
  }
  .wsdown-mobile > li:hover > a {
    box-shadow: none;
    background-color: $purpleblue-dark;
  }
  .wsdownmenu-list > li > a > .arrow {
    display: none !important;
  }

  /*.wsdown-mobile > li > a.active{ color:#fff; }
  .wsdown-mobile > li > a:hover{ color:#fff; }*/

  .wsdownmenu {
    width: 96%;
    //padding:48px 0px 0px 0px !important;
    background-color: #ffffff;
    height: 50px;
    // 		margin:22px 2%;
    //border:solid 1px #e0e0e0;
  }
  .wsdownmenu-list {
    display: none;
  }
  .wsdownmenu-list > li {
    width: 100%;
    float: none;
    text-align: left;
  }
  .wsdownmenu-list > li > a {
    border-right: none;
    padding: 13px 25px;
    line-height: 20px;
    text-transform: none;
  }
  .wsdownmenu-click {
    display: block;
  }
  .wsdownmenu-click02 {
    display: block;
  }
  .wsdownmenu-mobile-open {
    border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
  }
  .wsdownmenu-list > li:hover .wsdownmenu-submenu {
    display: none;
  }

  .wsdownmenu-submenu {
    position: static;
    width: 100%;
    display: none;
    padding-bottom: 15px;
  }
  .wsdownmenu-submenu > li {
    margin: 0px 0px 0px 0px !important;
  }
  .wsdownmenu-submenu > li > a {
    display: block;
    color: #fff;
    padding: 5px 25px 5px 35px;
  }
  .wsdownmenu-submenu > li a.active {
    color: #000 !important;
  }
  .wsdownmenu-submenu > li:hover > a {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: #fff;
  }

  .wsdownmenu-submenu .submenu > li > a {
    padding: 5px 25px 5px 45px;
  }
  .wsdownmenu-submenu .menu-item-has-children {
    .wsdownmenu-arrow, .caret {
      display: none;
    }
  }

  .wsdownmenu-submenu-sub {
    width: 100%;
    position: static;
    left: 100%;
    top: 0;
    display: none;
    margin: 0px;
    padding: 0px;
  }
  .wsdownmenu-submenu-sub > li {
    margin: 0px 0px 0px 0px !important;
    padding: 0px;
    position: relative;
  }
  .wsdownmenu-submenu-sub > li > a {
    display: block;
    color: #000;
    padding: 10px 25px 10px 45px;
    background: #fff !important;
    border-bottom: solid 1px #ccc;
    font-weight: normal;
  }
  .wsdownmenu-submenu-sub > li > a.active {
    color: #000 !important;
  }
  .wsdownmenu-submenu-sub > li:hover > a {
    background-color: #ccc !important;
    color: #fff;
  }

  .wsdownmenu-submenu-sub-sub {
    width: 100%;
    position: static;
    left: 100%;
    top: 0;
    display: none;
    margin: 0px;
    padding: 0px;
  }
  .wsdownmenu-submenu-sub-sub > li {
    margin: 0px 0px 0px 0px !important;
  }
  .wsdownmenu-submenu-sub-sub > li > a {
    display: block;
    color: #000;
    padding: 10px 25px 10px 25px;
    background: #fff !important;
    border-bottom: solid 1px #ccc;
    font-weight: normal;
  }
  .wsdownmenu-submenu-sub-sub > li a.active {
    color: #000 !important;
  }
  .wsdownmenu-submenu-sub-sub > li:hover > a {
    background-color: #606060 !important;
    color: #fff;
  }
  /* Mobile */

  .wsdownmenu-text {
    display: none !important;
  }

  .wsdownmenu-list .wsdownmenu-submenu li:hover .wsdownmenu-submenu-sub {
    display: none;
  }
  .wsdownmenu-list > li > .megamenu {
    position: static;
    display: none;
  }
  .wsdownmenu-list > li > .wsdownmenu-submenu {
    position: static !important;
  }
  .wsdownmenu-list .wsdownmenu-submenu .wsdownmenu-submenu-sub {
    position: static !important;
  }
  .wsdownmenu-list .wsdownmenu-submenu .wsdownmenu-submenu-sub .wsdownmenu-submenu-sub-sub {
    position: static !important;
  }

  .halfmenu {
    width: 100% !important;
  }
  .halfdiv {
    width: 100% !important;
  }

  /*Animated-arrow*/

  .wsdownmenu-animated-arrow {
    border-image: none;
    color: #333333;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding: 21px 14px;
    position: absolute;
    right: 0;
    text-align: center;
    display: block !important;
    top: 0;
    vertical-align: middle;
    width: 53px;
    height: 48px;
  }

  .wsdownmenu-animated-arrow span, .wsdownmenu-animated-arrow span:before, .wsdownmenu-animated-arrow span:after {
    cursor: pointer;
    height: 3px;
    width: 23px;
    background: #c9c9c9;
    position: absolute;
    display: block;
    content: '';
  }

  .wsdownmenu-animated-arrow span:before {
    top: -7px;
  }

  .wsdownmenu-animated-arrow span:after {
    bottom: -7px;
  }

  .wsdownmenu-animated-arrow span, .wsdownmenu-animated-arrow span:before, .wsdownmenu-animated-arrow span:after {
    transition: all 500ms ease-in-out;
  }

  .wsdownmenu-lines.wsdownmenu-animated-arrow span {
    background-color: transparent !important;
  }

  .wsdownmenu-lines.wsdownmenu-animated-arrow span:before, .wsdownmenu-animated-arrow.active span:after {
    top: 7px;
  }

  .wsdownmenu-lines.wsdownmenu-animated-arrow span:before {
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: 0px;
  }

  .wsdownmenu-lines.wsdownmenu-animated-arrow span:after {
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .wsdownmenu-click {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    cursor: pointer;
    z-index: 100;
    width: 100%;
    height: 46px;
  }
  .wsdownmenu-click i {
    display: block;
    height: 23px;
    width: 25px;
    margin-right: 8px;
    margin-top: 11px;
    background-size: 25px;
    font-size: 21px;
    color: #fff;
    float: right;
    text-align: center;
  }
  .wsdownmenu-rotate {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .wsdownmenu-text {
    width: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
    color: #424242;
    line-height: 48px;
    text-indent: 15px;
    font-weight: normal;
    font-size: 17px;
    display: none;
    text-transform: none;
  }
  .wsdownmenu-click02 {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    cursor: pointer;
    z-index: 100;
  }
  .wsdownmenu-click02 i {
    display: block;
    height: 23px;
    width: 25px;
    margin-top: 11px;
    margin-right: 12px;
    background-size: 25px;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.25);
    float: right;
    text-align: center;
  }
  .wsdownmenuarrow-rotate > i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .wsdownmenuarrow-rotate + a {
    background-color: $purpleblue-dark !important;
  }

  /*Animation None */
  .wsdownmenu-list li > .wsdownmenu-submenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important;
    background-color: $purpleblue-dark;
  }

  .wsdownmenu-list li:hover > .wsdownmenu-submenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important;
  }

  .wsdownmenu-submenu li > .wsdownmenu-submenu-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important;
  }

  .wsdownmenu-submenu li:hover > .wsdownmenu-submenu-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important;
  }

  .wsdownmenu-submenu-sub li > .wsdownmenu-submenu-sub-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important;
  }

  .wsdownmenu-submenu-sub li:hover > .wsdownmenu-submenu-sub-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important;
  }

  .wsdownmenu-list li > .megamenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important;
  }

  .wsdownmenu-list li:hover > .megamenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important;
  }

}

.navbar-default {
  border: none;
  background: none;
  margin-bottom: 0;

  .navbar-nav {

    >li{

      >a {
        color: #8b96b6;
        &:hover {
          color: white;
          background-color: #8b96b6;
        }
      }
      >&:hover {
        background-color: #8b96b6;
      }
    }
    >.active{
      a {
        color: #8b96b6;
        background: none;
       >&:hover {
          color: white;
          background-color: #8b96b6;
        }
      }
    }
  }
  .open{
    a{
      &:hover{

        background: #8b96b6 !important;
        color: white !important;


      }
    }
  }
}





@media only screen and (max-width: 767px) {


  .fancy-menu {
    display: inline-block;
    height: 41px;
  }

  .fancy-menu span {
    background: white;
    border-radius: 4px;
    display: block;
    height: 2px;
    margin-top: 5px;
    padding: 0;
    position: relative;
    transition: all 0.2s ease;
    width: 23px;
  }

  .fancy-menu.active span:nth-of-type(1) {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    top: 5px !important;
  }

  .fancy-menu.active span:nth-of-type(2) {
    opacity: 0;
  }

  .fancy-menu.active span:nth-of-type(3) {
    -webkit-transform: rotate3d(0,0,1,-45deg);
    transform: rotate3d(0,0,1,-45deg);
    top: -9px;
  }


.navbar-default{
  margin-bottom: 10px;


  .navbar-nav {


    li {

      a {
        color: white;
        &:hover{
          color: white;
          background-color: #9aa4c0;
        }

      }
    }
    .active{
      a {
        color: white;
        background: none;
        &:hover {
          color: white;
          background-color: #9aa4c0;
        }
      }
    }
  }
  .navbar-content {
    background-color: #8b96b6;

  }




  .navbar-nav {

    .open {
      background-color: white;
    }

    ul {
      a {
        color: #777 !important;
      }
    }

    .menu-item-has-children {
      > ul {
        position: relative;
        width: 100%;
        border: none;

        .dropdown-menu {
          margin: 0;
          left: 0;
          padding-left: 15px;
        }

      }
    }
  }


}
}