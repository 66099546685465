/*
	*
	*	Footer Review
	*
*/
.top-footer{
	.review{ 
	    h2{
		    color: $grayblue;
		    font-size: 20px;
		    background: url(../images/quote.png) no-repeat;
		    padding-top: 17px;
		    padding-left: 8px;
		    font-weight: 700;
	    }
	    p.text{
		    color: $grayblue;
		    font-size: 16px;
	    }
	    .naam{
		    color: $grayblue;
		    font-style: italic;
	    }
	}
}


/*
	*
	*	Reviews / Ervaringen Archive
	*
*/
.page-template-template-reviews,
.single-foto_custom_init {
	h1{
		span.behandeling{
			color: $antraciet-extralight;
		}
	}
	.collapse-button{
   	margin-top: 5px;
	    float: right;
	    line-height: 33px;
	    color: $skyblue;
	    font-size: 14px;
	    font-weight: 700;
	    position: relative;
	    z-index: 1;
	    cursor:pointer;
	    &:hover{
		    color:$skyblue-dark;
	    }
	}
}

.ul-behandeling {
   margin-top: 10px;
   padding-top: 5px;
   padding-bottom: 5px;
   border-bottom: solid thin #dfdfdf;
   border-top: solid thin #dfdfdf;
   z-index: 100;
   position: relative;
   
   &:first-of-type {
      z-index: 101;
   }
   
   .dropdown-menu {
      z-index: 1000;
   }
}

.list-reviews{
	.comment{
	    margin-top:20px;
	    margin-bottom:20px;
	    background-image: url(../images/review-left.png);
	    background-repeat: no-repeat;
	    background-position: 30% 100%;
	    &.col-right{
		    background-image: url(../images/review.png);
		    background-repeat: no-repeat;
		    background-position: 70% 100%;

	    }
		.message{
		    width: 100%;
		    padding: 25px 20px;
		    background-color: $grayblue-light;
		    h2{
			    font-size:20px;
			    margin-bottom:10px;
			    color: $skyblue-dark;
		    }
		    h4{
			    font-size: 15px;
			    color: $skyblue;
			    font-weight: 400;
		    }
		    .rating{
		        color: #fff;
			    font-size: 23px;
			    margin-top: 15px;
		    }
		}
		.name{
			text-align:right;
			color: #4e6974;
			font-size: 16px;
			padding-top:10px;
		}
	}
}