// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

//Search Form Custom
.search-form{
	.form-group{
		width:100%;
		max-width:350px;
		input[type="text"]{
			width:100%;
		}
	}
}

//Mobile Header Search
.mobilesearch {
    //background-color: #7aa4cf;
    
    z-index: 99;
    padding-bottom:15px;
    .mobsearch{
	  position: relative;  
    }
    form.searchform {
	    margin-top: 0px;
	    button.searchsubmit {
		    border:none;
		    cursor: pointer;
		    width: 34px;
		    height: 35px;
		    float: right;
		    margin: 5px;
		    position: absolute;
		    top: 0px;
		    right: 0px;
		    color: #002c61;
		    background-color: #e6e6e6; 
		}
	}
}

//Form Fields
form{
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="number"],
	textarea,
	.bootstrap-select>.dropdown-toggle{
		width: 100%;
		padding: 5px 10px;
		background-color:#FFF;
	    border: 3px solid #e6e6e6;
	    border-radius: 0;
	    line-height:29px;
	    color: #808080;  
	}
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="number"] {
   	height:45px;
	}
	i.form-control-feedback{
		height:45px;
		width:40px;
		line-height:45px;
	}
	
}

form[name="mc-embedded-subscribe-form"] {
   input[type="email"] {
      border-color: $brand-primary;
   }
}
.geb-dag, .geb-maand, .geb-jaar {
    width: 80px;
    display: inline-block;
    padding: 0 10px 0 0;
    margin-right: 10px;
    input {
       box-sizing: content-box;
    }
}

//Checkboxes & Radio
form{
	input[type=checkbox] { 
		display:none;
		cursor:pointer;
		& + label:before {
		  	content: "";
		    display:inline-block;
		    width:18px;
		    height:18px;
		    margin-right:15px;
		    color: #808080;
		    border: 3px solid #e6e6e6;
		    position:relative;
		    top:3px;
		  
		}

	} 
	input[type=checkbox]:checked{
		& + label:before{
		    background-color: $pink;
		    border-color: $pink-dark;
		}
	}

}

form label {
   font-weight: normal;
   color: #777;
	input[type=checkbox] { 
		display:none;
		cursor:pointer;
		& + span:before {
		  	content: "";
		    display:inline-block;
		    width:18px;
		    height:18px;
		    margin-right:15px;
		    color: #808080;
		    border: 3px solid #e6e6e6;
		    position:relative;
		    top:3px;
		  
		}

	} 
	input[type=checkbox]:checked{
		& + span:before{
		    background-color: $pink;
		    border-color: $pink-dark;
		}
	}

}
.behandelingen span.wpcf7-list-item {
    margin: 0;
    width: 50%;
    display: block;
    float: left;
}
input[type=submit] {
    display: inline-block;
    background-color: #fd7d82;
    color: #fff;
    box-shadow: 0 2px 5px 0 hsla(0,0%,61%,.5);
}

//Bootstrap Select
.bootstrap-select>.dropdown-toggle, 
.open>.btn-default.dropdown-toggle,
.open>.btn-default.dropdown-toggle:hover,
.open>.btn-default.dropdown-toggle:active,
.open>.btn-default.dropdown-toggle:focus,
.btn-group.open .dropdown-toggle,
.btn-default:active:focus, 
.btn-default:active:hover,
.dropdown-menu{
    line-height:29px;
	background-color:#FFF;
    border: 3px solid #e6e6e6;
    border-radius: 0;
    box-shadow:none;
    color: #808080;

}	
.btn-group.bootstrap-select{
	max-width:100%;
}
.dropdown-sub {
	position: relative;
}
.dropdown-menu{
	padding:0px;
	max-height: none !important;
	z-index:99;
	.inner{
		>li{
			>a{
				line-height:29px;
				padding-left: 12px;
			}
			>a:hover{
				border-color:#cacaca;
			}
		}
		>li:last-child{
			>a{
				border-bottom:0px;
			}
		}
	}

	//li.menu-item-has-children:hover {
    //
	//	.dropdown-menu {
	//		display: block;
	//		width: 100%;
	//		left: 100%;
	//		top: 0;
	//	}
	//}
}

//Gravity Form
.gform_wrapper{
	
	div.validation_error{
		color: $red;
	    border-color: #C0C0C0;
	    font-size: 14px;
	    font-weight: 200;
	    border-top:0px;
	    border-bottom:0px;
	    padding:0px;
	    margin-bottom:0px;
	}
	.gform_confirmation_message {
	    padding: 20px 0px;
	}
	
	
	.gform_body{
		ul{
			// Default Field
			li.gfield{
				margin-bottom: 10px;
				.gfield_label{
				    font-weight: 500;
				    font-size: 16px;
				    margin: 0px;
				}
			}
			
			//	Error Field
			li.gfield.gfield_error{
				background-color:transparent;
				border-width:0px;
				
				.gfield_label{
					color: $red;
				}
				input[type="text"]{
					border:3px $red solid;
				}
				.gfield_description.validation_message{
					display:none;
				}
			}
			
			// Inline Class Field
			.gfield.inline{
				width: 50%;
			    float: left;
			    clear: none;
			    padding-right: 30px;
			   
				input.medium,
				select.medium{
					width: 100%;
					margin-bottom: 5px;
				}
				
			}
			
			// Date 
			.gfield_date_day, 
			.gfield_date_month,
			.gfield_date_year {
			    width: 33.3% !important;
			    min-width: 75px;
			    margin: 0px;
			    padding-right:10px;
			    
			    label{
				    display:none;
			    }
/*
			    
			    &:not(:last-child){
				    input{
					    border-right-width: 0px;
					}
				}
*/
				
				input{
					width:100% !important;
				}

			}
			
			// Checkbox & Radio
			.gfield_checkbox,
			.gfield_radio{
				li{
					width:50%;
					display:inline-block;
					label{
						font-weight:500;
						color:#777;
						margin-left:0px;
					}
				}
			}
			
			// Textarea
			.ginput_container_textarea{
				textarea{
					padding: 5px 10px;
				}
			}
				
		}

	}
	.gform_footer{
			
		// Submit
		input[type="submit"]{
			display:inline-block;
			background-color: $pink;
	        color:#FFF;
	        @include shadow();
	        
	        &:hover,
	        &:focus,
	        &:active{
		        background-color: $pink-dark;
	        }
		}
	}
}



