/*
	*
	*	Footer Review
	*
*/
.top-footer{
	.chirurg{
	    color: $grayblue;
	    font-size: 16px;
		.row{
			padding:10px 0px; 
		}
		h2{
			color: $grayblue;
		    font-size: 20px;
		    font-weight: 400;
		}		    
	}
}

/*
	*
	*	Block Artsen
	*
*/
.block-artsen{
	 .image {
	    max-width: 100%;
	    height: 200px;
	    margin-top: 25px;
	    overflow: hidden;
	    display: block;
	}
}

/*
	*
	*	Single Arts
	*
*/
.single-ons{
	.meta{
		margin-bottom:15px;
		h1{
			padding-bottom:10px;
		}
		h3{
			color: $antraciet;
		}
	}
}