// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$navy:					#002c61;

$antraciet:				#345373;
$antraciet-light:		#546d87;
$antraciet-extralight:	#64829a;

$pink:					#fd7d82;
$pink-light:			#f6a0a3;
$pink-dark:				#ed6e72;

$google-red:            #C62828;

$youtube-red:           #E53935;

$rss-orange:            #F57C00;


$skyblue:				#7aa4cf;
$skyblue-dark:			#4a77a4;

$facebook-blue:         #0D47A1;

$twitter-blue:          #039BE5;

$whiteblue:				#c5e3f0;
$whiteblue-light:		#e2f3fa;
$whiteblue-dark:		#9ec2d1;

$grayblue:				#4e6974;
$grayblue-dark:			#dde7eb;
$grayblue-light:		#bfd2da;

$purpleblue:			#9aa4c0;
$purpleblue-dark:		#8b96b6;

$brown:               #e0d9d4;
$brown-dark:          #cdc4bd;

$brown-2:            #c1adaf;
$brown-2-dark:       #a3898d;

$purpleblue-2:       #b098b0;
$purpleblue-2-dark:  #8d768f;

$skyblue-2:             #9fc1dd;
$skyblue-2-dark:         #7e9eb9;

$lightpurple:        #c7b6c8;
$lightpurple-dark:   #b59fb6;

$brand-primary:         $navy;

$red:					#F00;

// ACCZ Kleuren
$lightblue-accz:	   #9fc1dd;
$normalblue-accz:	   #7d8db1;
$darkblue-accz:	       #002e62;
$red-accz:	           #ed241c;


//Fonts
$font-title: 			'Open Sans', Arial, sans-serif;
$font-body:	 			'Arial';
$font-button:			'Helvetica Neue';


//Settings
$header-height: 155px;
$page-header-height: 400px;

//Bootstrap Select
$color-red-error: rgb(185, 74, 72);
$color-grey-arrow: rgba(204, 204, 204, 0.2);
$width-default: 220px; // 3 960px-grid columns
$zindex-select-dropdown: 1060; // must be higher than a modal background (1050)
