/*
 * Row with equal height columns
 * --------------------------------------------------
 */
 @include mq-min( md ){
	.row-eq-height {
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display:         flex;
	}	 
}

