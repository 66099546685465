	
/*
	*
	*	Foto's en Ervaringen Menu
	*
*/
ul.ul-behandeling{
	>li {
	    display: inline-block;
	    .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){
		   width: auto;
		   &.open{
			   >.btn.dropdown-toggle {
				    border-color: #e6e6e6;
				    border-bottom-color: transparent;
				}
			}

		   >.dropdown-toggle{
			   border-color:transparent;
			   &:hover{
				   span{
					   color: $antraciet-extralight;
				   }
				}
		   }
		}
		button{
			span{
				 color: $antraciet;
			    font-size: 15px;
			}
		}

	}
}

/*
	*
	*	Foto Galerij
	*
*/
.gallery{
	
	figure.gallery-item {
		
		@include mq-min ( sm ){
			display: inline-block;
			float:left;
		    width: 32.6%;
		    margin-bottom: 15px;
		    margin-right:1%;
		    
		    &:nth-child(3n){
			    margin-right:0%;

		    }
		    &:nth-child(3n+1){
			    clear:left;
		    } 
		}

	}
	figcaption{
		font-size: 12px;
	    font-style: italic;
	    padding: 5px 0 5px;
	    color: $antraciet;
	}
		
	&:after {
	    clear: both;
	    content: "";
	    display: block;
    }
	
}

/*
	*
	*	Single Foto's
	*
*/
.single-foto_custom_init{
	.wrap[role="document"]{	
		a.linkback{
			float:right;
			line-height:33px;
			font-size:14px;
			font-weight:700;
			position: relative;
		    z-index: 1;
		}
	}
	
	span.pag-meta {
	    line-height: 35px;
	    display: inline-block;
	    float: right;
	}
}
