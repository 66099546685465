/* Breakpoints ---------------------------------------------------------------*/

@mixin mq($media-query) {
  @if $media-query == xs { @media only screen and (max-width: $screen-sm) { @content; } } // < 768px
  @if $media-query == sm { @media only screen and (max-width: $screen-md) { @content; } } // < 992px
  @if $media-query == md { @media only screen and (max-width: $screen-lg) { @content; } } // < 1066px
//   @if $media-query == lg { @media only screen and (max-width: $screen-lg) { @content; } }
}

@mixin mq-min($media-query) {
  @if $media-query == xs { @media only screen and (min-width: $screen-xs) { @content; } }  
  @if $media-query == sm { @media only screen and (min-width: $screen-sm) { @content; } }  // > 768px
  @if $media-query == md { @media only screen and (min-width: $screen-md) { @content; } }  // > 992px
  @if $media-query == lg { @media only screen and (min-width: $screen-lg) { @content; } } 
}
/* ---------------------------------------------------------------------------*/

@mixin shadow(){
	-webkit-box-shadow: 0px 2px 5px 0px rgba(155, 155, 155, 0.5);
	-moz-box-shadow:    0px 2px 5px 0px rgba(155, 155, 155, 0.5);
	box-shadow:         0px 2px 5px 0px rgba(155, 155, 155, 0.5);
}

/* Colours -------------------------------------------------------------------*/

$palettes: (
  main: (
    base:     #8b96b6,
    blue:     #002e60,
    red:      #e31818,
    gray:     #f0f0f0,
    darkgray: #808080
  )
);

@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}

// Example usage
// First value is selected colourgroup
// Second value is specific shade in colourgroup
.testColour {
  background-color: palette(main, gray);
}
