.wrap[role="document"]{
	margin-top: 40px;
	@include mq-min( md ){
		padding-top: $header-height;
	}
	
	article,
	aside{
		padding-top:40px;
		padding-bottom:40px;
	}
	
	article{
		
	}
	
	.calltoaction{
		color: $pink;
		
		.btn{
			margin-bottom:10px;
		}	
	}
	
	
	.sep {
	    border: 2px solid #e6e6e6;
	    width: 100%;
	    margin-top: 20px;
	    margin-bottom: 20px;
	}
	.sep.blue {
	    border: 2px solid #e3f3fa;
	}
	
	a.linkthrough{
	    color: $skyblue;
	    font-size:12px;
	    font-weight: 700;
	    &:hover,
	    &:focus,
	    &:active{
		    color: $skyblue-dark;
	    }
	}
	a.linkback {
		color: $skyblue;
	    font-size: 12px;
	    font-weight: 700;
	    &:hover,
	    &:focus,
	    &:active{
		    color: $skyblue-dark;
	    }
	}
	
	/*
		Pagination
	*/
	.pagination{
	    margin: 0px;
	    &>li{
		    >a{
			    color: $antraciet;
			    border-color: $skyblue; 
		    }
		    >a.active {
			    background-color:$skyblue;
			    border-color: $skyblue;
			    color: #FFF;
			}
		}
	}
	
	.ngg-navigation {
   	font-size: inherit !important;
   	text-align: left;
   	margin: 20px 0 0 !important;
   	padding: 0 !important;
   	text-align: left;
   	display: inline-block !important;
      @include border-right-radius(4px);
      @include border-left-radius(4px);
      border: solid thin $skyblue;
      overflow: hidden;
      
      a, span { display: inline-block; }
      
      .current {
         padding: 6px 12px;
         margin: 0;
         background-color: $skyblue;
         color: #fff;
      }
      
      .prev, 
      .next { 
         text-indent: -9999px;   
         position: relative;
      }
      
      .prev:after, 
      .next:after { 
         position: absolute;
         top: 0;
         left: 0;
         text-indent: 0;
         font-family: 'FontAwesome'; 
         text-align: center;
         width: 100%;
         height: 100%;
         padding-top: 5px;  
      }
      
      .prev:after {
         content: '\f104';
      }
      
      .next:after {
         content: '\f105';
      }
      
      a {
         border: none;
         padding: 6px 12px;
         margin: 0;
         
         &:hover {
            background-color: #eee;
            color: $antraciet !important;
         }
      }
      
      span+a, a+a { border-left: solid thin $skyblue; }
	}
	
	/*
		Nieuws Archief	
	*/
	.minipost{
		.date{
			width: 65px;
		    height: 65px;
		    border-radius:999px;
		    background-color: $skyblue;
		    text-align:center;
		    font-family: $font-title;
		    color:#FFF;
		    
		    span{
			    display: block;	    
		        font-size: 27px;
			    line-height: 55px;
		    }
		    small{
			    display: block;
			    margin-top: -20px;
			    line-height: 25px;
			    font-size: 12px;
			    font-weight: 700;
			    text-transform: uppercase;
		    }
		    
		}
		.year{
		    color: $grayblue;
         position: relative;
         font-size: 13px;
         width: 65px;
         text-align: center;
         margin: 10px 0;
	    }
		img{
			display:block;
			&.alignnone{
				float:none;
				margin-left:0px;
			}
			&:after{
				content:"";
				display:block;
				clear:both;
			}
		}
		h2{
			font-size:23px;
			margin-bottom:10px;
		}
	}

}

// Paginatie
.nav-links {
   margin-bottom: 50px;
   display: inline-block;
   width: 100%;
   .nav-previous {
      float: left;
      &:before {
         content: '‹';
         margin-right: 6px;
      }
   }
   .nav-next {
      float:right;
      &:after {
         content: '›';
         margin-left: 6px;
      }
   }
}

/*
	*
	*	Search Pagina
	*
*/
.search{
	.wrap[role="document"]{
	
		span.search-numbers {
		    font-size: 15px;
		    color: #999;
		    float: right;
		    font-weight: 600;
		}
		.search-meta{
			padding-bottom:25px;
		}
		.search-results{
			article{
				padding:10px 0px 15px 0px;			
			}
		}
		h2{
			padding-bottom:10px;
		}
		a.moretag{
			display:block;
		}
	
	}
}
