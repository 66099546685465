.fast-menu-container {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 3;
   
   .container {
      max-width: 1600px;
      width: 100%;
   }
   
   @include mq(xs) {
   	display: none;
	}

   .fast-menu {
      right: 0;
      margin-top: 25px;
      text-align: right;
      position: absolute;
      li {
         display: block;
         
         a {
            padding: 5px 15px;
            font-size: 12px;
            font-weight: bold;
            color: #fff !important;
            display: inline-block;
            background-color: $brown-2;
            cursor: pointer;
            @include transition(background 0.3s ease-out);
            
            &:hover { background-color: darken($brown-2,10%); }
         }
         
         &:nth-child(2) {
            a { 
               background-color: $purpleblue-2; 
               &:hover { background-color: darken($purpleblue-2,10%); }
            }
         }
         
         &:nth-child(3) {
            a { 
               background-color: $skyblue-2; 
               &:hover { background-color: darken($skyblue-2,10%); }
            }
         }
         
         +li {
            margin-top: 5px;
         }
      }
   }
}

.bekend-van-container {
   position: absolute;
   bottom: 25px;
   right: 14px;
   width: auto;
   z-index: 3;
   @include mq(xs) {
      width: 100%;
   }
   p {
      text-align: right;
      overflow: hidden;
      @include mq(sm) {
         text-align: left;
         margin-left: 23px;
      }
      
      @include mq(xs) {
         font-size: 12px;
         text-align: center;
      }
      
      img {
         height: auto;
         width: auto;
         max-width: 58px;
         max-height: 20px;
         display: block;
         float: right;
         clear: both;
         margin: 9px 0 0;
         @include mq(xs) {
            clear: none;
            float: none;
            margin-right: 20px;
            display: inline-block;
         }
      }
   }
}

header.banner{	
   position: relative;
   z-index: 102;
	background-color:#FFF;
	@include mq-min( md ){
		padding-top:15px;
		height:$header-height;
		position:fixed;
		width:100%;
		z-index:999;
	}
	@include mq( sm ){
		padding-top:15px;
	}
	
	
	/*
		Logo
	*/
	#navImg{
		display:block;
		margin-top: 10px;
		padding-left: 0;
		

		img{
			max-width:100%;
			height:58px;
			width: auto;
			margin-bottom:20px;	
			
			@include mq( xs ){
				max-width:170px;
				display:block;
				margin-left:auto;
				margin-right:auto;
			}
		}
	}
	
	@include mq-min( md ){
		form {
		   margin-top: 15px;
		}
	}
	
	
	/*
		Mini Contact
	*/
	.minicontact{
      padding-left: 45px;
      float: right;
      width: auto;
      margin-right: 6px;
		.icon{
			position:absolute;
		    left: 0;
		    top: 5px;
			i{
				color: $pink;
				font-size:35px;
			}
		}
		.specs{
			text-align:right;
			span{
				display:block;
			}
			.tekst{
				color:$pink;
				font-size: 11px;
			}
			.telefoon{
				color:$pink;
				font-family: $font-title;
				font-size: 18px;
				font-weight: 400;    
			}
			.int,
			.openingstijden{
				font-size: 13px;
			    color: $navy;
			    line-height:15px;
			}
		}
		
		@include mq(sm) {
   		padding-left: 10px;
   		margin-top: 15px;
   		
   		.icon {
      		position: static;
      		display: inline-block;
      		left: auto;
      		top: auto;
      		right: auto;
      		bottom: auto;
      		margin-right: 10px;
   		}
   		.specs { text-align: left; display: inline-block; }
   		.int, .openingstijden {
      		display: none !important;
   		}
		}
	}
	/*
		Desktop Menu
	*/
	@include mq-min( md ){
		nav.nav-primary{
			>ul{
				li{
					float: left;
				    padding: 0;
				    background-color: rgba(0, 0, 0, 0);
				    width: auto;
				}
			}
		}
	}
	
	/*
		Mobile Buttons
	*/
	.mobile-buttons{
		color: $navy;
		text-align: center;
	}
	
}

.lang-xs {
   display: inline-block;
   width: 64px;
   margin-top: 0;
   
   button {
      height: 43px;
   }
}

@include mq(xs) {
   .wsdownmenu-animated-arrow {
      display: none !important;
   }
   
   .mobile-buttons .wsdownmenu-animated-arrow {
      display: inline-block !important;
      width: auto;
      height: 41px;
      width: 64px;
      position: relative;
      top: auto;
      bottom: auto;
      
      span {
         left: 21px;
         top: 18px;
         background-color: #fff;
         
         &:before, &:after {
            left: 0px;
            background-color: #fff;
         }
      }
      
      &.wsdownmenu-lines {
         span {
            top: 13px;
         }
      }
   } 
   
   #menu-top-menu {
      position: relative;
      z-index: 10;
      width: calc(100% + 40px);
      margin-left: -20px;
   }
}

@media only screen and (min-width:768px) and (max-width:780px) {
   .wsdownmenu-animated-arrow {
      display: block !important;
      z-index: 10;
   }
}