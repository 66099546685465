/*
	*
	*	Sidebar
	*
*/
aside.sidebar{
	.widget{
		margin-bottom:15px;
		padding-bottom: 15px;
		
		+.widget {
   		margin-top: 15px;
   		padding-top: 30px;
         border-top: solid thin #dfdfdf;
		}
	}
	ul{
   	&#localmenu {
      	li:first-child {
         	display: none;
      	}
   	}
   	
		li.menu-item a {
		    width: 100%;
		    height: 100%;
		    display: block;
		    font-size: 16px;
		    color: $skyblue;
		    line-height: 40px;
		    padding: 0 20px;
		    border-left: 3px solid #e3f3fa;
		    &:hover,
		    &:active,
		    &:focus{
			    color:$antraciet;
		    }

		}
		li.menu-item.active a {
		    border: 3px solid #e3f3fa;
		    border-left: none;
		    color: $antraciet;
		    line-height: 34px;
		}
	} 
}

/*
	*
	*	C2A Widget
	*
*/
aside{
	.calltoaction{
   	padding-bottom: 0;
		text-align:center;
		padding-top:0px;
		
		small{
			font-style: italic;
		}
		
		.btn{
			display:block;
			float: none;
			margin-right: 0;
			@include shadow();
		}
	}
}


/*
	*
	*	Recente Berichten Widget
	*
*/
aside{
	.recentposts{
		.minipost{
			padding: 10px 0;	

			.text{
				padding-left:0px;
				
				@include mq(md) {
   				padding-left: 15px;
				}
				p{
				    color: $antraciet-light;
				}	
			}
		}
	}
	a.linkthrough{
		float:right;
	}
	
}

/*
	*
	*	Beoordeling Widget
	*
*/
aside{
	.beoordeling{
		h3{
			line-height:30px;
		}
		.grade{
			width: 80px;
		    height: 80px;
		    background-color: #daf6c6;
		    border-radius:999px;
		    text-align:center;
		    font-family: $font-title;
		    font-weight: bold;
		    padding-top:1px;
		    position: relative;
		    top: -10px;
		    margin-bottom: -10px;
		    span{
			    display: block;	    
		        font-size: 27px;
			    line-height: 80px;
			    color:#8fbb71;
		    }
		    
		    @include mq(xs) {
   		    top: 0;
   		    margin-bottom: 0;
		    }
	    }
	}
}

/*
	*
	*	Nieuwsbrief Widget
	*
*/
aside {
   .nieuwsbrief-widget {
      h2+p {
         margin-top: 5px;
         margin-bottom: 10px;
      }
   }
}
#mc-embedded-subscribe-form{
	position: relative;
	
	input[type="submit"]{
	    position: absolute;
	    width: 32px;
	    height: 32px;
	    right: 0px;
	    margin: 6px 6px;
	    padding:0px;
	    border-radius: 0;
	    -moz-border-radius: 0;
	    -webkit-border-radius: 0;
	    border: none;
	    background: $brand-primary;
	    color: #FFF;
	    cursor: pointer;
	}
}

/*
	*
	*	Locatie Widget
	*
*/
aside{
	.locatie{
		img{
			width:100% !important;
			height:auto !important;
		}
	}
}

// CF7 NIeuwsbrief inschrijving
.nieuwsbrief-widget {
   form.wpcf7-form{
      position: relative;
      input[type="email"] {
         border-color: $brand-primary;
      }
      input[type="submit"]{
          position: absolute;
          width: 32px;
          height: 32px;
          right: 0px;
          top:0;
          margin: 6px 6px;
          padding:0px;
          border-radius: 0;
          -moz-border-radius: 0;
          -webkit-border-radius: 0;
          border: none;
          background: $brand-primary;
          color: #FFF;
          cursor: pointer;
      }
   } 
}

div.wpcf7-validation-errors {
   display: none !important;
}
