/*
	===

		Homepage

	===
*/
.block-homepage{
   border-left: 7px solid white;
   border-right: 7px solid white;
	h2{
	    color: white;
	    font-size: 22px;
	    font-weight: bolder;
	    text-transform: uppercase;
	    &+p {
   	    margin-top: 5px;
	    }
	}
	p{
	  color: #7b6162;
	}

	.btn{
	    position:absolute;
		bottom:50px;
		margin: 10px auto 0px auto;
		background-color: $skyblue !important;

		&:hover {
   		background-color: $skyblue-dark !important;
		}
	}

	a.lees-meer {
      position: absolute;
      bottom: 20px;
      color: white;
      right: 50px;
      font-weight: bold;
      text-transform: uppercase;
	}

	.col-md-4{
		position:relative;
		padding: 30px 5% 50px;
		border: 7px solid white;
		@include mq(sm) {
   		+div {
   	      //margin-top: 30px;
   	   }
      }
	}
}
.home.single-page {
   .page-head {
      margin: 0;
      @include mq(sm) {
         height:386px;
         margin-bottom: 7px;
      }
      &:after,
      &:before {
         content:none;
      }
      .left,
      .right {
         border: 7px solid white;
         padding: 0;
      }
      .left {
         border-left: 0;
         @include mq(sm) {
            border:none;
         }
      }
      .right {
         border-right: 0;
         @include mq(sm) {
            background-color: rgba(239,239,239,.8);
            border:none;
         }
         h1 {
            font-size: 38px;
         }
         h2{
				font-size:48px;
				color: #a0c2de;
				font-family: 'rage_italicregular';
				letter-spacing: -0.02em;
				margin-right: 14px;
			}
         .text {
            position: relative;
            top: 50%;
            width: auto;
            display: inline-block;
            left:50%;
            @include transform(translateY(-50%) translateX(-50%));
            text-align: center;

            @include mq-min(md) {
               border-right: 60px solid transparent;
            }
            height: auto;
            img {
               width: 100%;
                   padding: 0 8px 0 2px;
            }
         }
      }
      .left .homepage-slider {
         height: 386px;
         .owl-item {
            height: 386px;
         }
      }
   }
}


.stats {
   text-align: center;
   margin-top: 40px;

   .stat {
      padding-left: 0 !important;
      padding-right: 0 !important;

      img {
         width: 70px;
         height: 70px;
      }

      font-size: 26px;

      p { min-height: 44px; }

      h5 {
         margin-top: 0 ;
         color: inherit;
      }
   }
}

/*
	===

		Single / Page

	===
*/
.single,
.search,
.single-page,
.archive,
.blog {

	/*
		Page Head
	*/
	.page-head{
      background-color: #efefef;
		height:$page-header-height;
		overflow:hidden;
		position: relative;
		z-index: 0;
      @include mq(sm) {
         &:after, &:before {
         content: '';
         top: 0;
         height: 100%;
         position: absolute;
         z-index: 1;
         width: 100%;
         right: 0;
         @include background-image(linear-gradient(to left, rgba(8,5,6,0), #efefef));
         }
      }

		@include mq(sm) {
   		&:before { display: none; }
		}
		.left,
		.right {
   		border: 7px solid #fff;
		}
		.left{
			background-repeat: no-repeat;
			background-size: cover;
         background-position: top center;
		   z-index: 0;

		   .img{
			    position: absolute;
			    left: 0px;
			    display: block;
			    width: auto;
			    height: 100%;
			    max-height: 100%;
			    overflow:hidden;
				&:after{
					content: "";
				    display: block;
				    position: absolute;
				    height: 100%;
				    width: 150px;
				    right: 0px;
				    top:0px;

				    background: -webkit-linear-gradient(left, rgba(237,237,237,0), rgba(237,237,237,1)); /* For Safari 5.1 to 6.0 */
				    background: -o-linear-gradient(right, rgba(237,237,237,0), rgba(237,237,237,1)); /* For Opera 11.1 to 12.0 */
				    background: -moz-linear-gradient(right, rgba(237,237,237,0), rgba(237,237,237,1)); /* For Firefox 3.6 to 15 */
				    background: linear-gradient(to right, rgba(226,243,250,0), rgba(226,243,250,1)); /* Standard syntax (must be last) */
				}
				img{
					min-height:100%;
					max-width:none;
					width:auto;

				}
		    }

          @include mq(sm) {
             position: absolute;
             width: 100%;
             height: 100%;
             background-position: center center;
             background-repeat: no-repeat;
             background-size: cover;
          }

          .homepage-slider {
             height: $page-header-height;

             .owl-item {
                height: $page-header-height;
             }

             .item {
                display: block;
                height: $page-header-height;
                width: 100%;
                background-position: top center;
                background-repeat: no-repeat;
                background-size: cover;
             }
          }
		}
		.right{
			position:relative;
			height:100%;
			z-index: 2;
			.text{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 535px;
            padding: 0 35px;
			}
			h1{
				font-weight: 700;
				font-size:30px;
				padding-bottom:15px;
				text-transform: uppercase;
            letter-spacing: .2em;
				color: #ccb6b9;
			}

			@include mq(sm) {
   			padding-left: 45px;
   			padding-right: 45px;
			}

			@include mq(xs) {
   			padding-left: 30px;
   			padding-right: 30px;

   			.text {
      			padding: 0;

      			h1 {
         			letter-spacing: 0;
      			}
   			}
			}
		}
	}

	/*
		Top Footer
	*/
	.top-footer{
		padding: 45px 0;
		background-color: $grayblue-dark;
	}
}

/*
	===

		Page Vestigingen

	===
*/
.locations{
   margin-top: 30px;

	.row{
		margin-bottom:15px;
	}
}

.calltoaction {
   padding: 40px 0;

   .btn {
      float: left;
      margin-right: 30px;

      @include mq(xs) {
         float: none !important;
         margin: 0 auto;
      }
   }

   small {
      line-height: 1;
   }

   p {
      display: inline-block;
   }
}

.gallery {
   margin-top: 15px;
}


// Page Contact
[class*=block-grid-]>* {
   box-sizing: initial;
}

.tarieven table {
  h3 {
    margin-top: 30px;
  }
  h4 {
    margin-top: 15px;
  }
}
