
footer {

  .content-info {
    padding: 30px 0;
    background-color: #f6a0a3;
    color: #FFF;
    font-size: 13px;
    h3 {
      color: #FFF;
      font-family: 'Arial';
    }
    ul {
      li {
        a {
          &,
          &:hover,
          &:focus,
          &:active {
            color: #FFF;
            text-transform: none;
          }
          &:hover {
            color: $pink-dark;
          }
        }
      }
    }
  }

  .scrolltop {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    background-image: url(../images/scrolltop.png);
    cursor: pointer;
  }

  .other {
    padding: 40px 0px;
    background-color: #fafafa;

    .block-partners {
      padding-bottom: 30px;
      a {
        display: block;
        text-align: center;
      }
      img {
        display: inline-block;
      }
    }

    .bottom-footer {
      ul.footer-menu {
        li {
          display: inline-block;
          float: left;
          line-height: 40px;
          &,
          a {
            color: $antraciet-light;
            font-size: 13px;
            padding: 0px 0px 0px 5px;
          }
          a {
            line-height: 14px;
            border-left: 1px $antraciet-light solid;
          }
        }
      }

      /*
          Share This
      */
      .sharethis {
        text-align: right;
      }

      /*
          Social Media
      */
      ul.socialmedia {
        li {
          a {
            margin: 5px 8px 5px 0px;
            float: left;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            color: white;
            text-align: center;
            font-size: 14px;
            line-height: 32px;
            backface-visibility: hidden;
            transition: background .3s ease;

            &.google {
              background-color: $google-red;

              &:hover {
                background-color: darken($google-red,10);
              }
            }

            &.facebook {
              background-color:$facebook-blue;
              &:hover {
                background-color: darken($facebook-blue,9);
              }
            }

            &.twitter {
              background-color: $twitter-blue;
              &:hover {
                background-color: darken($twitter-blue,10);
              }
            }

            &.youtube {
              font-size: 20px;
              background-color: $youtube-red;
              &:hover {
                background-color: darken($youtube-red,10);
              }
            }

            &.rss {
              background-color: $rss-orange;
              &:hover {
                background-color: darken($rss-orange,10);
              }
            }

            &.instagram {
              background: black;
              font-size: 19px;
              &:after, :before{
                transform: translate(-50%, -50%);
              }
              &:before{
                border-radius: 50%;
              }
            }

          }
        }
      }
    }

  }
}