/*----------------------------------------------------------------------------*/
/* TYPOGRAPHY ----------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/

/* Font Options -------------------------------------------------------------- */

/*
$font-smaller: em(16);
$font-small: em(18);
$font-medium: em(20);
$font-large: em(24);
$font-larger: em(28);
$font-largest: em(60);

$h1-font-sizes: (
  null: (em(30), 1.2), // Null = default styles.
  sm: (em(40), 1.2),
  md: ($font-largest, 1.2),
  lg: ($font-largest, 1.2)
);

$h1-font-sizes-alt: (
  null: ($font-medium, 1.2), // Null = default styles.
  sm: ($font-large, 1.2),
  md: ($font-larger, 1.2),
  lg: (em(40), 1.2)
);

$h2-font-sizes: (
  null: ($font-medium, 1.2),
  sm: ($font-medium, 1.2),
  md: ($font-large, 1.2),
  lg: ($font-larger, 1.2)
);

$h3-font-sizes: (
  null: ($font-medium, 1.2),
  sm: ($font-medium, 1.2),
  md: ($font-medium, 1.2),
  lg: ($font-large, 1.2)
);

$h4-font-sizes: (
  null: ($font-small, 1.2),
  sm: ($font-medium, 1.2),
  md: ($font-medium, 1.2),
  lg: ($font-medium, 1.2)
);

$h5-font-sizes: (
  null: ($font-small * 0.85, 1.2),
  sm: ($font-small * 0.9, 1.2),
  md: ($font-small, 1.2),
  lg: ($font-small, 1.2)
);

$reading-font-sizes: (
  null: ($font-smaller * 0.95, 21px),
  sm: ($font-smaller, 24px),
  md: ($font-smaller, 24px),
  lg: ($font-smaller, 24px)
);
*/

@font-face {
    font-family: 'rage_italicregular';
    src: url('../fonts/rage_italic_regular-webfont.eot');
    src: url('../fonts/rage_italic_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/rage_italic_regular-webfont.woff2') format('woff2'),
         url('../fonts/rage_italic_regular-webfont.woff') format('woff'),
         url('../fonts/rage_italic_regular-webfont.ttf') format('truetype'),
         url('../fonts/rage_italic_regular-webfont.svg#rage_italicregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

html, body { 
  font-family: $font-body, Arial, sans-serif;
  font-size: 15px;
  color: $antraciet;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  
}


/* Reset ---------------------------------------------------------------------*/

p, h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
  -webkit-transform: translateZ(0px);
}
p{
	line-height:1.7;
}

/* Titels ---------------------------------------------------------------------*/



h1, h2, h3, h4, h5, h6{
   &,
   & a{
      color: $skyblue; 
      font-family: $font-title;
      font-weight:300;
   }
}
h1{
	font-size: 30px;
}
h2{
	font-size:25px;
}
h3{
    font-size: 18px;
    font-weight: 700;
}

h4{
	font-size:16px;
}
h5{
	font-size:16px;
}
h6{
	font-size:16px;
}

i { 
	font-style: normal; 
	text-decoration: none;
}

/* Links -------------------------------------------------------------------*/
.content{
	a{
		color: $skyblue-dark;

		&:hover,
		&:focus{
			text-decoration: none;
			color: $skyblue;
		}
	}
}

/* Margins ---------------------- */
h1+p, h1+ul, h1+ol, h1+table {
  margin-top: 30px;
}
h2+p, h2+ul, h2+ol, h2+table {
  margin-top: 25px;
}
h3+p, h3+ul, h3+ol, h3+table {
  margin-top: 20px;
}
h4+p, h4+ul, h4+ol, h4+table {
  margin-top: 10px;
}

p+p, ul+ul, p+ul, ul+p, h2+blockquote+p {
  margin-top: 15px;
}
p+h2, ul+h2, ol+h2, table+h2 {
  margin-top: 60px;
}
p+h3, ul+h3, ol+h3, table+h3 {
  margin-top: 50px;
}
p+h4, ul+h4, ol+h4, table+h4 {
  margin-top: 40px;
}
p+h5, ul+h5, ol+h5, table+h5 {
  margin-top: 30px;
}
*[role="main"] {
  ul {
    li+li {
      margin-top: 5px;
    }
  }
}

/* Helpers -------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6, p, blockquote, b, pre, code {
  &.left { text-align: left; }
  &.center { text-align: center; }
  &.right { text-align: right; }
}
