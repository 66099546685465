.btn:not(.dropdown-toggle),
form input[type=submit]{
    padding:10px 25px;
    border-width:0px;
    @include border-right-radius(5px);
    @include border-left-radius(5px);
    font-family:$font-button;
    font-weight:600;
    font-size:15px;
    cursor: pointer;
    text-align:center;

    &.btn-default{
        background-color: $skyblue;
        color:#FFF;
    }
    
    &.btn-pink{
	    background-color: $pink;
        color:#FFF;
        @include shadow();
        
        &:hover,
        &:focus,
        &:active{
	        background-color: $pink-dark;
        }
    }
    
    &.btn-blue {
       background-color: $skyblue;
       color: #FFF;
       
       &:hover,
        &:focus,
        &:active{
           background-color: $skyblue-dark;
        }
    }
    
    &.btn-purple {
       background-color: $purpleblue;
       color: #FFF;
       
       &:hover,
        &:focus,
        &:active{
           background-color: $purpleblue-dark;
        }
    }
    
    &.btn-alt {
      @include border-right-radius(0);
      @include border-left-radius(0);
      
      @include box-shadow(none !important);
    }
    
    &.bekijk-meer {
      width: 100%; 
      text-transform: lowercase;
      white-space: inherit;
      background-color: $lightpurple;
      color: white;
      @include shadow();
      &:first-letter {
         text-transform: uppercase;
      }
      &:hover,
      &:focus,
      &:active{
         background-color: $lightpurple-dark;
      }
    }

}
