/*
	*
	* Promotie Templates
	*
*/
html.base-promotie{
	
	// Main Settings
	body{
		font-family:'Open Sans',Helvetica,Arial,sans-serif;
		color:palette( main, blue);
		font-weight:400;
		overflow-x:hidden;
		background-color:white;
		font-size:16px;
		line-height:24px;
	}
	
	// Content
	.wrap[role="document"]{
		padding-top:0px;
		
		h1,
		h2,
		h3,
		h4{
			color: #8b96b6;
		    font-family: 'Open Sans',Helvetica,Arial,sans-serif;
		    font-weight: 700;
		    line-height: 1.2;
		    margin: 0;
		    padding: 0;
		}
		h2{
			font-size:34px; 
			
		}
		a:focus,
		a:hover,
		a:visited{
			color: palette( main, blue);
		}
		
		// Button
		a.button,
		input[type=submit]{
		    display: inline-block;
		    border: none;
		    padding: 20px 35px;
		    font-size: 18px;
		    color: #FFF;
		    border-top-left-radius: 5px;
		    border-top-right-radius: 5px;
		    border-bottom-left-radius: 5px;
		    border-bottom-right-radius: 5px;
		    font-weight: 600;
		    vertical-align: middle;
		    position: relative;
		    z-index: 1;
		    -webkit-backface-visibility: hidden;
		    -webkit-transition: all 0.3s ease-in-out;
		    -moz-transition: all 0.3s ease-in-out;
		    transition: all 0.3s ease-in-out;
		    outline: none !important;
		    text-decoration: none !important;
		    &:before {    
		        content: '';
			    position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
			    border: 2px solid palette( main, blue);
			    z-index: -1;
			    border-top-left-radius: inherit;
			    border-top-right-radius: inherit;
			    border-bottom-left-radius: inherit;
			    border-bottom-right-radius: inherit;
			    opacity: 0;
			    -webkit-transform: scale3d(0.6, 0.6, 1);
			    transform: scale3d(0.6, 0.6, 1);
			    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
			    transition: transform 0.3s, opacity 0.3s;
			    -webkit-transition-timing-function: cubic-bezier(0.75,0,0.125,1);
			    transition-timing-function: cubic-bezier(0.75,0,0.125,1);
			}
			&:after{
				content: '';
			    position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
			    z-index: -1;
			    border-top-left-radius: inherit;
			    border-top-right-radius: inherit;
			    border-bottom-left-radius: inherit;
			    border-bottom-right-radius: inherit;
			    background-color: #e31818;
			    -webkit-transition: all 0.3s ease-in-out;
			    -moz-transition: all 0.3s ease-in-out;
			    transition: all 0.3s ease-in-out;
			}
			&:hover{
			    text-decoration: none;
			    color: palette( main, blue);
			    &:before{
				    -webkit-transform: scale3d(1,1,1);
				    transform: scale3d(1,1,1);
				    opacity: 1;
			    }
			    &:after{
			        opacity: 0;
			    }
			}

		}
		
		// Call To Action
		div.action{
		    margin-top: 50px;
		    p{
			    color: palette( main, blue);
			    font-weight:600;
				.button {
				    float: left;
				    margin-right: 30px;
				    margin-top: -6px;
				    text-align: center;
				}
			}
		}
		
		// Social Media
		.social-media{
					
			p{
				display: table-cell;
				height: 80px;
				vertical-align: middle;
				margin-bottom: 0;
				padding-right: 15px;
				
				@include mq(xs) {
					height: 50px;
				}
									
			}
			a {
				display: inline-block;
				color: palette(main);
				width: 30px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				float:left;
				@include border-top-radius(50%);
				@include border-bottom-radius(50%);
				
				-webkit-transition: all 0.3s ease-in-out;
			    -moz-transition: all 0.3s ease-in-out;
			    transition: all 0.3s ease-in-out;
			    box-shadow: inset 0px 0px 0px 1px palette( main, blue);
			    -moz-box-shadow: inset 0px 0px 0px 1px palette( main, blue);
			    -webkit-box-shadow: inset 0px 0px 0px 1px palette( main, blue);
				
				&:hover{
					color: #FFF !important;
				    box-shadow: inset 0px 0px 0px 30px #8b96b6;
				    -moz-box-shadow: inset 0px 0px 0px 30px #8b96b6;
				    -webkit-box-shadow: inset 0px 0px 0px 30px #8b96b6;
				}
				
			}	
			a+a {
			  margin-left: 5px;
			}

		}

		// Header
		header {
			overflow: hidden;
			
			#top {
				padding: 10px 0;
				background-color: white;
				font-size: 14px;
		
				@include mq(xs) {
				  padding-bottom: 0;
				}
				
				img {
					width:auto;
				    height:80px;
					@include mq(xs) {
						height: 50px;
						width: auto;
					}
				}	
				
			}
			//End Top
			
			.highlight {
			  background-color: palette(main);
			  color: white;
			  margin-top: -10px;
			  margin-bottom: -10px;
			  
			  @include mq(xs) {
			    margin-top: 10px;
			    margin-bottom: 0;
			  }
			  
				p {
					display: table-cell;
					height: 100px;
					vertical-align: middle;
					padding-left: 15px;
					
					@include mq(xs) {
						height: auto;
						padding: 5px 0;
					}
					
					a{
					    color:#FFF;
					}
					i{
						display: inline-block;
						width: 20px;
					}
				}
			  
				&:after {
					content: '';
					position: absolute;
					left: 100%;
					top: 0px;
					width: 300%;
					height: 100px;
					background-color: palette(main);
					
					@include mq(xs) {
						display: none;
					}
				}
			}
			//End Highlight
			
			.middle {
				background-image: url('../images/promotie/header.jpg');
				background-position: top center;
				background-repeat: no-repeat;
				background-size: cover; 
				padding-top: 140px;
				
				@include mq(xs) {
				  padding-top: 50px;
				}
				
				.middle-footer {
					margin-top: -250px;
					padding-top: 400px;
					@include linear-gradient(to top, white, rgba(255,255,255,0));
					background-color: transparent;
					
					@include mq(xs) {
						padding-top: 250px;
						margin-top: -150px;
					}
					
					p {
						color: palette(main, darkgray);
					}
				}
				
				&.eyes {
				    background-image: url("../images/promotie/header.jpg");
				    background-position: top center;
				    background-repeat: no-repeat;
				    background-size: cover;
				    padding-top: 140px; 
			    }
				&.ooglidcorrectie {
				    background-image: url("../images/promotie/header-ooglidcorrectie.jpg");
				    background-position: top center;
				    background-repeat: no-repeat;
				    background-size: cover;
				    padding-top: 140px; 
			    }
				&.breast {
					background-image: url("../images/promotie/borstvergroting.jpg");
					background-position: top center;
					background-repeat: no-repeat;
					background-size: cover;
					padding-top: 140px; 
				}
				&.breasts {
					background-image: url("../images/promotie/borstvergroting2.jpg");
					background-position: top center;
					background-repeat: no-repeat;
					background-size: cover;
					padding-top: 140px; 
				}
				
				.heading {
					font-size: 55px;
					color: palette( main, blue); 
					
					@include mq( xs){
						font-size: 34px; 
					}
				}
				
				.subheading {
					color: palette( main, blue);
					font-weight: 600;
					font-size: 34px;
					margin-top: 50px;
					margin-bottom: 20px;
					line-height: 1.2; 
					
					@include mq( xs){
						font-size: 22; 
					}
					
					strong {
					    font-weight: 700; 
				    }
				
				}
				
			}
			//End Middle
			
			
		}
		// End Header
		
		// Begin Content
		section{
			&[role="main"],
			[role="main"]{
				padding-top: 50px;
				padding-bottom: 50px; 
			}
		}
		
		// Waarom Aklinieken -  Sectie
		section.why{
			h2 {
			 margin-bottom: 20px; 
			}
			.list-check li {
				font-size: 18px;
				margin-top: 30px; 
				@include mq( xs ){
					text-align: center;
				}
			}
			.logos {
				margin-top: 50px;
				display: table;
				width: 100%; 
				.logo-col {
				    display: table-cell;
				    text-align: left;
				    max-width: 20%;
				    min-width: 100px;
				    padding: 0 10px; 
			    }
			    .logo-col:first-child {
					padding-left: 0; 
				}
				.logo-col:last-child {
			    	padding-right: 0; 
			    }
		
			}
		}
		
		// Action - Sectie			    
		section.action {
			background-color: #8b96b6;
			color: white;
			position: relative;
			overflow: hidden; 
			h2,
			h3{
				color: #FFF; 
			}
			h3{
				margin-top: 30px;
			}
			.container-absolute {
   			position: absolute;
            right: -25px;
            top: 0;
            bottom: 0;
            .row,
            .row > div {
               height: 100%;
            }
            .row .imge {
               background-position: center center;
               background-repeat: no-repeat;
               background-size: cover;
               overflow: hidden;
               width: 100%;
               height: 50%;
            }
			    @include mq-min( lg){
				    iframe { 
						margin-top:70px;
					}
			    }
			    .images{
				    .img {
						background-position: center center;
						background-repeat: no-repeat;
						background-size: cover;
						overflow: hidden;
						width: 100%; 
					}
					.img:nth-child(1) {
			        	background-image: url("../images/promotie/eye-picture1.jpg"); 
			        }
			        .img:nth-child(2) {
			        	background-image: url("../images/promotie/eye-picture2.jpg"); 
			        }
			    }
		    }  
		    
		    				    
		}




	
		// Aklinieken -  Sectie
		section.aklinieken{
			.a-klinieken-logo {
				display: block;
				transform: translateY(50%);
			    -moz-transform: translateY(50%);
			    -webkit-transform: translateY(50%);
			    @include mq( md ){
				    transform: translateY(0%);
			      -moz-transform: translateY(0%);
			      -webkit-transform: translateY(0%); 
			    }
			}
		
			@include mq( md ){
		 		.a-klinieken-logo {
		 			margin-bottom: 30px;
		    	}
		 	}
		 	.social-media{
			    a{
			        color: palette( main, blue );
			        &:hover{
				       box-shadow: inset 0px 0px 0px 30px palette( main, blue); 
					    -moz-box-shadow: inset 0px 0px 0px 30px palette( main, blue);
					    -webkit-box-shadow: inset 0px 0px 0px 30px palette( main, blue);
			        }
			    }
		    }
		 	
		} 
		
		// Eyes Sectie (foto's)
		section.eyes {
			width: 100%;
			overflow: hidden; 
			.eye,
			.breast {
			    position: relative;
			    float: left;
			    width: 33.3333333333%;
			    height: 155px;
			    background-position: center center;
			    background-repeat: no-repeat;
			    background-size: cover; 
			    
			    @include mq( xs ){
				    height: 60px; 
			    }
			   
			    &:after{
					content: '';
					width: 100%;
					height: 100%;
					background-color: palette( main, blue);
					opacity: 0.8;
					position: absolute;
					top: 0;
					left: 0;  
			    }
			    
			}
			
			.breast { 
		    	height:170px;
				
				&:after { 
					opacity: 0.7;
				}
				&:nth-child(1) {
					background-image: url("../images/promotie/breast-picture1.jpg");
					margin-left: -1px; 
				}
				&:nth-child(2) {
					margin-left: 1px;
					margin-right: 1px;
					background-image: url("../images/promotie/breast-picture2.jpg");
				}
				&:nth-child(3) {
					background-image: url("../images/promotie/breast-picture3.jpg");
					margin-right: -1px; 
				}
		    }
		    
		    .eye{
			    &:nth-child(1) {
					background-image: url("../images/promotie/eye-picture1.jpg");
					margin-left: -1px; 
			    }
		    
			    &:nth-child(2) {
					margin-left: 1px;
					margin-right: 1px;
					background-image: url("../images/promotie/eye-picture2.jpg"); 
				}
				&:nth-child(3) {
					background-image: url("../images/promotie/eye-picture3.jpg");
					margin-right: -1px; 
				}
		    }
		    
		    .eye.alt{
				&:nth-child(1) {
					background-image: url("../images/promotie/eye-picture1-2.jpg");
					margin-left: -1px; 
				}
				&:nth-child(2) {
					margin-left: 1px;
					margin-right: 1px;
					background-image: url("../images/promotie/eye-picture2-2.jpg"); 
				}
				&:nth-child(3) {
					background-image: url("../images/promotie/eye-picture3-2.jpg");
					margin-right: -1px; 
				}
		    }
		
		}
		
		// Meer behandelingen Sectie
		section.aklinieken-more {
			background-color: #f0f0f0;
			
			.links {
				margin-top: 50px;
				margin-bottom: 20px; 
				
				@include mq ( xs ){
					margin-bottom: 35px;
				}
				
				a.link{
				    display: block;
				    position: relative;
				    padding: 15px;
				    border: solid thin palette( main, blue);
				    color: palette(main, blue);
				    text-align: center;
				    margin-bottom: 30px;
				    z-index: 0;
				    -webkit-transition: all 0.3s ease-in-out;
				    -moz-transition: all 0.3s ease-in-out;
				    transition: all 0.3s ease-in-out;
				    &:after{
				        content: '';
					    opacity: 0;
					    height: 0%;
					    width: 100%;
					    position: absolute;
					    display: block;
					    top: 0;
					    left: 0;
					    z-index: -1;
					    background-color: palette( main, blue);
					    -webkit-transition: all 0.3s ease-in-out;
					    -moz-transition: all 0.3s ease-in-out;
					    transition: all 0.3s ease-in-out;
				    }
				    &:hover{
					    color: #FFF;
					    &:after{
					        height: 100%;
							opacity: 1;
					    }
				    }
				}
			}
			
			.action {
				display: inline-block; 
			}	
			
		}	
		
		// Contact Sectie
		section.contact{
			
			.addresses {
				margin-top: 50px; 
				
				@include mq( xs){
					margin-top: 20px; 
				}
				img {
					width: 100%;
					height: auto;
					margin-bottom: 15px; 
					@include mq( xs ){
						margin-top: 30px; 
					}
			    }
			    
			}
		
		
		}
		
		
		footer {
			background-color: #f0f0f0;
			color: #808080;
			font-size: 12px;
			padding: 15px 0; 
			
			a,
			a:visited{
				color: #808080;	
				&:hover,
				&:focus,
				&:active{
					text-decoration:underline;
					color: #808080;	
				}
				
			}
			@include mq( xs){
				.text-right {
					text-align: left !important;
				}
			}
			
		}   
	}
	
}	

#contact.modal{
	.modal-body {
		padding: 30px;
	}
	.gform_wrapper{
		margin:0px;
		
		div.validation_error{
			display:none;
		}
		.gform_body{
			ul{
				
				li.gfield{
					.gfield_label{
						font-weight:700;
					}
					.gfield_required{
						display:none;
					}
				}
				
				.gfield_error{
					 input.large,
					 select.large{
						 width:100%
					 }
					.gfield_description.validation_message{
						display:block;
						color: $red;
					}
					.gfield_label{
						color: palette( main, blue );
					}
				}
				.gfield_error.gfield_contains_required{
					margin:0px;
					div.ginput_container{
						margin-left:0px;
					}
				}
			}
		}
		.gform_footer{
			padding-bottom:0px;
			padding-top:0px;
		}
		
		input[type="text"] {
			height: 50px;
			line-height: 50px;
		}			
		input[type=submit]{
		    background-color: #e31818;
		    box-shadow: none;
		    line-height: 30px;
		}
		
	}
}

.modal.in .modal-dialog.modal-vertical-centered {
	-webkit-transform: translate(0, 50%) !important;
	-moz-transform: translate(0, 50%) !important;
	-ms-transform: translate(0, 50%) !important;
	-o-transform: translate(0, 50%) !important;
	transform: translate(0, 50%) !important; 
}
  


