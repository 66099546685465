*,
a,
.bootstrap-select .dropdown-toggle{
	&:focus,
	&:active{
		outline: none !important;
	}
}

ul,
ol{
	padding:0px;
	li{
		list-style-type:none;
	}
}

img{
	max-width:100%;
	height:auto;
}

#__bs_notify__{
	background-color:red !important;
	font-size:20px !important;
}

.table.table-prices {
	td+td {
		min-width: 110px;
		font-weight: bold;
	}
}

.liberta_embed {
  &--no-cookie {
    background-color: $whiteblue-light;
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      max-width: 80%;
      text-align: center;
    }
  }
}

button.gdpr-preferences {
  background-color: #7AA4D0;
  color: #fff;
  box-shadow: 0 2px 5px 0 hsla(0,0%,61%,.5);
  margin: 20px 0;
  padding: 10px 25px;
  border-width: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  font-family: Helvetica Neue;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
}
