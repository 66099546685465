
/* Color Theme */
.gry{background:#424242; color:#fff;}
.orange {background:#ff670f; color:#fff;}
.blue {background:#4A89DC; color:#fff;}
.green{background:#74a52e; color:#fff;}
.red{background:#DA4B38; color:#fff;}
.yellow{background:#F6BB42; color:#fff;}
.purple{background:#967ADC; color:#fff;}
.pink{background:#ee1289; color:#fff;}
.chocolate {background:#934915; color:#fff;}


.gry > .wsdownmenu-list > li > a{color:#424242; border-right:1px solid rgba(0,0,0,0.10); }
.gry > .wsdownmenu-list > li > a{background-color:#fff !important;}
.gry > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#E5E5E5 !important;}
.gry > .wsdownmenu-list > li:hover>a{background:#424242 !important; color:#fff !important;}
.gry > .wsdownmenu-list > li > a:hover {background:#424242 !important; color:#fff !important;}
.gry > .wsdownmenu-list > li > a.active{background:#424242 !important; color:#fff !important;}
.gry > .wsdownmenu-submenu > li > a:hover {background:#424242; border-radius:0px!important; color:#fff;}
.gry .wsdownmenu-list li a:hover .arrow:after{ border-top-color:#fff;}
.gry .wsdownmenu-list li a.active .arrow:after{ border-top-color:#fff;}
.gry .wsdownmenu-list li:hover>a .arrow:after{ border-top-color:#fff;}

.orange > .wsdownmenu-list > li > a{color:#424242; border-right:1px solid rgba(0,0,0,0.10); }
.orange > .wsdownmenu-list > li > a{background-color:#fff !important;}
.orange > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#E5E5E5 !important;}
.orange > .wsdownmenu-list > li:hover>a{background:#ff670f !important; color:#fff !important;}
.orange > .wsdownmenu-list > li > a:hover {background:#ff670f !important; color:#fff !important;}
.orange > .wsdownmenu-list > li > a.active{background:#ff670f !important; color:#fff !important;}
.orange > .wsdownmenu-submenu > li > a:hover {background:#ff670f; border-radius:0px!important; color:#fff;}
.orange .wsdownmenu-list li a:hover .arrow:after{ border-top-color:#fff;}
.orange .wsdownmenu-list li a.active .arrow:after{ border-top-color:#fff;}
.orange .wsdownmenu-list li:hover>a .arrow:after{ border-top-color:#fff;}

.blue > .wsdownmenu-list > li > a{color:#424242; border-right:1px solid rgba(0,0,0,0.10); }
.blue > .wsdownmenu-list > li > a{background-color:#fff !important;}
.blue > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#E5E5E5 !important;}
.blue > .wsdownmenu-list > li:hover>a{background:#4A89DC !important; color:#fff !important;}
.blue > .wsdownmenu-list > li > a:hover {background:#4A89DC !important; color:#fff !important;}
.blue > .wsdownmenu-list > li > a.active{background:#4A89DC !important; color:#fff !important;}
.blue > .wsdownmenu-submenu li > a:hover {background:#4A89DC !important; border-radius:0px!important; color:#fff !important;}
.blue .wsdownmenu-list li a:hover .arrow:after{ border-top-color:#fff;}
.blue .wsdownmenu-list li a.active .arrow:after{ border-top-color:#fff;}
.blue .wsdownmenu-list li:hover>a .arrow:after{ border-top-color:#fff;}

.green > .wsdownmenu-list > li > a{color:#424242; border-right:1px solid rgba(0,0,0,0.10); }
.green > .wsdownmenu-list > li > a{background-color:#fff !important;}
.green > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#E5E5E5 !important;}
.green > .wsdownmenu-list > li:hover>a{background:#74a52e !important; color:#fff !important;}
.green > .wsdownmenu-list > li > a:hover {background:#74a52e !important; color:#fff !important;}
.green > .wsdownmenu-list > li > a.active{background:#74a52e !important; color:#fff !important;}
.green > .wsdownmenu-submenu > li > a:hover {background:#74a52e !important; border-radius:0px!important; color:#fff !important;}
.green .wsdownmenu-list li a:hover .arrow:after{ border-top-color:#fff;}
.green .wsdownmenu-list li a.active .arrow:after{ border-top-color:#fff;}
.green .wsdownmenu-list li:hover>a .arrow:after{ border-top-color:#fff;}

.red > .wsdownmenu-list > li > a{color:#424242; border-right:1px solid rgba(0,0,0,0.10); }
.red > .wsdownmenu-list > li > a{background-color:#fff !important;}
.red > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#E5E5E5 !important;}
.red > .wsdownmenu-list > li:hover>a{background:#DA4B38 !important; color:#fff !important;}
.red > .wsdownmenu-list > li > a:hover {background:#DA4B38 !important; color:#fff !important;}
.red > .wsdownmenu-list > li > a.active{background:#DA4B38 !important; color:#fff !important;}
.red > .wsdownmenu-submenu > li > a:hover {background:#DA4B38 !important; border-radius:0px!important; color:#fff !important;}
.red .wsdownmenu-list li a:hover .arrow:after{ border-top-color:#fff;}
.red .wsdownmenu-list li a.active .arrow:after{ border-top-color:#fff;}
.red .wsdownmenu-list li:hover>a .arrow:after{ border-top-color:#fff;}

.yellow > .wsdownmenu-list > li > a{color:#424242; border-right:1px solid rgba(0,0,0,0.10); }
.yellow > .wsdownmenu-list > li > a{background-color:#fff !important;}
.yellow > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#E5E5E5 !important;}
.yellow > .wsdownmenu-list > li:hover>a{background:#F6BB42 !important; color:#fff !important;}
.yellow > .wsdownmenu-list > li > a:hover {background:#F6BB42 !important; color:#fff !important;}
.yellow > .wsdownmenu-list > li > a.active{background:#F6BB42 !important; color:#fff !important;}
.yellow > .wsdownmenu-submenu > li > a:hover {background:#F6BB42 !important; border-radius:0px!important; color:#fff !important;}
.yellow .wsdownmenu-list li a:hover .arrow:after{ border-top-color:#fff;}
.yellow .wsdownmenu-list li a.active .arrow:after{ border-top-color:#fff;}
.yellow .wsdownmenu-list li:hover>a .arrow:after{ border-top-color:#fff;}
.yellow > .wsmenu-list li a .fa{color:#424242 !important;}


.purple > .wsdownmenu-list > li > a{color:#424242; border-right:1px solid rgba(0,0,0,0.10); }
.purple > .wsdownmenu-list > li > a{background-color:#fff !important;}
.purple > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#E5E5E5 !important;}
.purple > .wsdownmenu-list > li:hover>a{background:#967ADC !important; color:#fff !important;}
.purple > .wsdownmenu-list > li > a:hover {background:#967ADC !important; color:#fff !important;}
.purple > .wsdownmenu-list > li > a.active{background:#967ADC !important; color:#fff !important;}
.purple > .wsdownmenu-submenu > li > a:hover {background:#967ADC !important; border-radius:0px!important; color:#fff !important;}
.purple .wsdownmenu-list li a:hover .arrow:after{ border-top-color:#fff;}
.purple .wsdownmenu-list li a.active .arrow:after{ border-top-color:#fff;}
.purple .wsdownmenu-list li:hover>a .arrow:after{ border-top-color:#fff;}

.pink > .wsdownmenu-list > li > a{color:#424242; border-right:1px solid rgba(0,0,0,0.10); }
.pink > .wsdownmenu-list > li > a{background-color:#fff !important;}
.pink > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#E5E5E5 !important;}
.pink > .wsdownmenu-list > li:hover>a{background:#ee1289 !important; color:#fff !important;}
.pink > .wsdownmenu-list > li > a:hover {background:#ee1289 !important; color:#fff !important;}
.pink > .wsdownmenu-list > li > a.active{background:#ee1289 !important; color:#fff !important;}
.pink > .wsdownmenu-submenu > li > a:hover {background:#ee1289 !important; border-radius:0px!important; color:#fff !important;}
.pink .wsdownmenu-list li a:hover .arrow:after{ border-top-color:#fff;}
.pink .wsdownmenu-list li a.active .arrow:after{ border-top-color:#fff;}
.pink .wsdownmenu-list li:hover>a .arrow:after{ border-top-color:#fff;}

.chocolate > .wsdownmenu-list > li > a{color:#424242; border-right:1px solid rgba(0,0,0,0.10); }
.chocolate > .wsdownmenu-list > li > a{background-color:#fff !important;}
.chocolate > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#E5E5E5 !important;}
.chocolate > .wsdownmenu-list > li:hover>a{background:#934915 !important; color:#fff !important;}
.chocolate > .wsdownmenu-list > li a:hover {background:#934915 !important; color:#fff !important;}
.chocolate > .wsdownmenu-list > li a.active{background:#934915 !important; color:#fff !important;}
.chocolate > .wsdownmenu-submenu > li a:hover {background:#934915 !important; border-radius:0px!important; color:#fff !important;}
.chocolate .wsdownmenu-list li a:hover .arrow:after{ border-top-color:#fff;}
.chocolate .wsdownmenu-list li a.active .arrow:after{ border-top-color:#fff;}
.chocolate .wsdownmenu-list li:hover>a .arrow:after{ border-top-color:#fff;}




/* Gradient Theme */
.tranbg{background-color:transparent !important;}
.whitebg{ background-color:#fff !important; }
.whitebg:hover{ color:#000 !important; }
.blue-grdt{
	background: #5999ee;
	background: -moz-linear-gradient(top,  #5999ee 0%, #4a89dc 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#5999ee), color-stop(100%,#4a89dc));
	background: -webkit-linear-gradient(top,  #5999ee 0%,#4a89dc 100%);
	background: -o-linear-gradient(top,  #5999ee 0%,#4a89dc 100%);
	background: -ms-linear-gradient(top,  #5999ee 0%,#4a89dc 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5999ee', endColorstr='#4a89dc',GradientType=0 );
	color:#fff;}
	
.gry-grdt{
	background: #565656;
	background: -moz-linear-gradient(top,  #565656 0%, #424242 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#565656), color-stop(100%,#424242));
	background: -webkit-linear-gradient(top,  #565656 0%,#424242 100%);
	background: -o-linear-gradient(top,  #565656 0%,#424242 100%);
	background: -ms-linear-gradient(top,  #565656 0%,#424242 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#565656', endColorstr='#424242',GradientType=0 );
	color:#fff;}
	
.green-grdt{
	background: #86ba3d;
	background: -moz-linear-gradient(top,  #86ba3d 0%, #74a52e 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#86ba3d), color-stop(100%,#74a52e));
	background: -webkit-linear-gradient(top,  #86ba3d 0%,#74a52e 100%);
	background: -o-linear-gradient(top,  #86ba3d 0%,#74a52e 100%);
	background: -ms-linear-gradient(top,  #86ba3d 0%,#74a52e 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#86ba3d', endColorstr='#74a52e',GradientType=0 );
	color:#fff;}	

.red-grdt{
	background: #f05c48;
	background: -moz-linear-gradient(top,  #f05c48 0%, #da4b38 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f05c48), color-stop(100%,#da4b38));
	background: -webkit-linear-gradient(top,  #f05c48 0%,#da4b38 100%);
	background: -o-linear-gradient(top,  #f05c48 0%,#da4b38 100%);
	background: -ms-linear-gradient(top,  #f05c48 0%,#da4b38 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f05c48', endColorstr='#da4b38',GradientType=0 );
	color:#fff;}	
		
.orange-grdt{
	background: #fc7d33;
	background: -moz-linear-gradient(top,  #fc7d33 0%, #ff670f 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fc7d33), color-stop(100%,#ff670f));
	background: -webkit-linear-gradient(top,  #fc7d33 0%,#ff670f 100%);
	background: -o-linear-gradient(top,  #fc7d33 0%,#ff670f 100%);
	background: -ms-linear-gradient(top,  #fc7d33 0%,#ff670f 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc7d33', endColorstr='#ff670f',GradientType=0 );
	color:#fff;}	

.yellow-grdt{
	background: #ffcd67;
	background: -moz-linear-gradient(top,  #ffcd67 0%, #f6bb42 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffcd67), color-stop(100%,#f6bb42));
	background: -webkit-linear-gradient(top,  #ffcd67 0%,#ff670f 100%);
	background: -o-linear-gradient(top,  #ffcd67 0%,#f6bb42 100%);
	background: -ms-linear-gradient(top,  #ffcd67 0%,#f6bb42 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffcd67', endColorstr='#f6bb42',GradientType=0 );
	color:#fff;}	

.purple-grdt{
	background: #a98ded;
	background: -moz-linear-gradient(top,  #a98ded 0%, #967adc 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#a98ded), color-stop(100%,#967adc));
	background: -webkit-linear-gradient(top,  #a98ded 0%,#967adc 100%);
	background: -o-linear-gradient(top,  #a98ded 0%,#967adc 100%);
	background: -ms-linear-gradient(top,  #a98ded 0%,#967adc 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a98ded', endColorstr='#967adc',GradientType=0 );
	color:#fff;}	
			
.pink-grdt{
	background: #fb3ea4;
	background: -moz-linear-gradient(top,  #fb3ea4 0%, #f21b8f 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fb3ea4), color-stop(100%,#f21b8f));
	background: -webkit-linear-gradient(top,  #fb3ea4 0%,#f21b8f 100%);
	background: -o-linear-gradient(top,  #fb3ea4 0%,#f21b8f 100%);
	background: -ms-linear-gradient(top,  #fb3ea4 0%,#f21b8f 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb3ea4', endColorstr='#f21b8f',GradientType=0 );
	color:#fff;}	

.tranbg > .wsdownmenu-list > li > a{color:#e5e5e5; border-right:none; background-color:transparent; }
.tranbg > .wsdownmenu-list > li > a:hover{background-color:transparent; }
.tranbg > .wsdownmenu-list > li > a{background-color:transparent; }
.tranbg > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#E5E5E5;}
		

.whitebg > .wsdownmenu-list > li > a{color:#424242; border-right:1px solid rgba(0,0,0,0.10); }
.whitebg > .wsdownmenu-list > li > a{background-color:#fff !important;}
.whitebg > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#E5E5E5 !important;}
					
.red-grdt > .wsdownmenu-list > li > a{color:#fff !important;}
.red-grdt > .wsdownmenu-list > li > a{background-color:transparent !important;}
.red-grdt > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#fff !important;}
.red-grdt > .wsdownmenu-text{ color:#fff !important; }

.gry-grdt > .wsdownmenu-list > li > a{color:#fff !important;}
.gry-grdt > .wsdownmenu-list > li > a{background-color:transparent !important;}
.gry-grdt > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#fff !important;}
.gry-grdt > .wsdownmenu-text{ color:#fff !important; }

.blue-grdt > .wsdownmenu-list > li > a{color:#fff !important;}
.blue-grdt > .wsdownmenu-list > li > a{background-color:transparent !important;}
.blue-grdt > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#fff !important;}
.blue-grdt > .wsdownmenu-text{ color:#fff !important; }

.green-grdt > .wsdownmenu-list > li > a{color:#fff !important;}
.green-grdt > .wsdownmenu-list > li > a{background-color:transparent !important;}
.green-grdt > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#fff !important;}
.green-grdt > .wsdownmenu-text{ color:#fff !important; }

.orange-grdt > .wsdownmenu-list > li > a{color:#fff !important;}
.orange-grdt > .wsdownmenu-list > li > a{background-color:transparent !important;}
.orange-grdt > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#fff !important;}
.orange-grdt > .wsdownmenu-text{ color:#fff !important; }


.yellow-grdt > .wsdownmenu-list > li > a{color:#424242 !important;}
.yellow-grdt > .wsdownmenu-list > li > a{background-color:transparent !important;}
.yellow-grdt > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#424242 !important;}
.yellow-grdt > .wsdownmenu-list li a .fa{color:#424242 !important;}

.yellow-grdt > .wsdownmenu-animated-arrow span, .yellow-grdt > .wsdownmenu-animated-arrow span:before, .yellow-grdt > .wsdownmenu-animated-arrow span:after{background:#424242 !important; }

.yellow-grdt > .wsdownmenu-text{color:#424242 !important; }


.purple-grdt > .wsdownmenu-list > li > a{color:#fff !important;}
.purple-grdt > .wsdownmenu-list > li > a{background-color:transparent !important;}
.purple-grdt > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#fff !important;}
.purple-grdt > .wsdownmenu-text{ color:#fff !important; }


.pink-grdt > .wsdownmenu-list > li > a{color:#fff !important;}
.pink-grdt > .wsdownmenu-list > li > a{background-color:transparent !important;}
.pink-grdt > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#fff !important;}
.pink-grdt > .wsdownmenu-text{ color:#fff !important; }

 
@media only screen and (max-width: 780px) {
 
.tranbg > .wsdownmenu-list > li > a{color:#424242 !important; border-right:none; background-color:#fff !important; }
.tranbg > .wsdownmenu-list > li > a:hover{background-color:#fff !important; }
.tranbg > .wsdownmenu-list > li > a{background-color:#fff !important; }
.tranbg > .wsdownmenu-list > li > a > .arrow:after{ border-top-color:#E5E5E5;}
		
		
.red-grdt > .wsdownmenu-list > li > a > .fa{ color:#fff !important; }
.blue-grdt > .wsdownmenu-list > li > a > .fa{ color:#fff !important; }
.orange-grdt > .wsdownmenu-list > li > a > .fa{ color:#fff !important; }
.green-grdt > .wsdownmenu-list > li > a > .fa{ color:#fff !important; }
.purple-grdt > .wsdownmenu-list > li > a > .fa{ color:#fff !important; }
.pink-grdt > .wsdownmenu-list > li > a > .fa{ color:#fff !important; }

/* Color Theme */
.gry > .wsdownmenu-submenu > li:hover>a{ background-color:#424242 !important; color:#fff;}
.gry > .wsdownmenu .wsdownmenu-list > li > a.active{color: #fff; background-color: #424242;}
.gry > .wsdownmenu .wsdownmenu-list > li > a:hover{ color: #fff; background-color: #424242;}

.orange > .wsdownmenu-submenu > li:hover>a{ background-color:#ff670f !important; color:#fff;}
.orange > .wsdownmenu > .wsdownmenu-list > li > a.active{color: #fff; background-color: #ff670f;}
.orange > .wsdownmenu > .wsdownmenu-list > li > a:hover{ color: #fff; background-color: #ff670f;}

.blue > .wsdownmenu-submenu > li:hover>a{ background-color:#4A89DC !important; color:#fff;}
.blue > .wsdownmenu > .wsdownmenu-list > li > a.active{color: #fff; background-color: #4A89DC;}
.blue > .wsdownmenu > .wsdownmenu-list > li > a:hover{ color: #fff; background-color: #4A89DC;}	

.green > .wsdownmenu-submenu > li:hover>a{ background-color:#74a52e !important; color:#fff;}
.green > .wsdownmenu > .wsdownmenu-list > li > a.active{color: #fff; background-color: #74a52e;}
.green > .wsdownmenu > .wsdownmenu-list > li > a:hover{ color: #fff; background-color: #74a52e;}	

.red > .wsdownmenu-submenu > li:hover>a{ background-color:#DA4B38 !important; color:#fff;}
.red > .wsdownmenu > .wsdownmenu-list > li > a.active{color: #fff; background-color: #DA4B38;}
.red > .wsdownmenu > .wsdownmenu-list > li > a:hover{ color: #fff; background-color: #DA4B38;}	

.yellow > .wsdownmenu-submenu > li:hover>a{ background-color:#F6BB42 !important; color:#fff;}
.yellow > .wsdownmenu .wsdownmenu-list > li > a.active{color: #fff; background-color: #F6BB42;}
.yellow > .wsdownmenu .wsdownmenu-list > li > a:hover{ color: #fff; background-color: #F6BB42;}	

.purple > .wsdownmenu-submenu > li:hover>a{ background-color:#967ADC !important; color:#fff;}
.purple > .wsdownmenu > .wsdownmenu-list > li > a.active{color: #fff; background-color: #967ADC;}
.purple > .wsdownmenu > .wsdownmenu-list > li > a:hover{ color: #fff; background-color: #967ADC;}	
	
.pink > .wsdownmenu-submenu > li:hover>a{ background-color:#ee1289 !important; color:#fff;}
.pink > .wsdownmenu > .wsdownmenu-list > li > a.active{color: #fff; background-color: #ee1289;}
.pink > .wsdownmenu > .wsdownmenu-list > li > a:hover{ color: #fff; background-color: #ee1289;}	
	
.chocolate > .wsdownmenu-submenu > li:hover>a{ background-color:#934915 !important; color:#fff;}
.chocolate > .wsdownmenu > .wsdownmenu-list > li > a.active{color: #fff; background-color: #934915;}
.chocolate > .wsdownmenu > .wsdownmenu-list > li > a:hover{ color: #fff; background-color: #934915;}	

}
